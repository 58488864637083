<template>
  <main>
    <div class="contenu2">

      <div style="position:relative">
        <!-- mobile -->
        <div class="logo mobile">
          <router-link to="/"><img src="../assets/images/logo.png"></router-link>
        </div>
        <img class="top-mobile mobile" src="../assets/images/top-vague3-mobile.jpg">
        <!-- commun -->
        <div class="top-comment"><img src="../assets/images/comment-participer.png"></div>
      </div>
      <div class="grosse-tache">
        <img class="point1" src="../assets/images/point1.png">
        <div class="cmt-wrapper">
          <div class="cmt">
            <div class="cmt-l"><img src="/images/dentistes.png"></div>
            <div><h2>le dentiste / cabinet dentaire</h2>
            <p>5010993556632 / 5010994956653 / 5010993791835</p></div>
          </div>
          <div class="cmt">
            <div class="cmt-l"><img src="/images/caisse.png"></div>
            <div><h2>la caisse enregistreuse</h2>
            <p>5010993696376</p></div>
          </div>
          <div class="cmt">
            <div class="cmt-l"><img src="/images/coiffeur.png"></div>
            <div><h2>le coiffeur / coiffeur créatif</h2>
            <p>5010993510870 / 5010993565078 / 5010993791859</p></div>
          </div>
          <div class="cmt">
            <div class="cmt-l"><img src="/images/20pots.png"></div>
            <div><h2>pack de 20 pots</h2>
            <p>5010994913434 / 5010993431281 / 5010993557448</p></div>
          </div>
          <div class="cmt">
            <div class="cmt-l"><img src="/images/4pots.png"></div>
            <div><h2>pack de <span class="spfont">4</span> pots</h2>
            <p>5010994815707 / 5010994945787 / 5010994047375<br />
              5010994947033 / 5010993425303 / 5010993310166 <br />
              5010993558957 / 5010993558988 / 5010993323821</p></div>
          </div>
          <div class="cmt">
            <div class="cmt-l"><img src="/images/paillettes.png"></div>
           <div> <h2>pack pâte à paillettes</h2>
            <p>5010994803360 / 5010994914547 / 5010993544325</p></div>
          </div>
          <div class="cmt">
            <div class="cmt-l"><img src="/images/super-boite.png"></div>
            <div><h2>la super boîte d'accessoires</h2>
            <p>5010993712281</p></div>
          </div>

        </div>
        <div class="points desktop"><img src="../assets/images/points.jpg">
          <p class="p1">avant le 31/03/2021 inclus.</p>
          <p class="p2">La photo du ticket de caisse/facture du produit, en entourant le libellé, le prix et la date d’achat du produit, ainsi que la photo du code-barres du produit. Si ces informations ne sont pas clairement visibles, la demande de participation sera nulle et ne pourra être traitée.</p>
          <p class="p3">Les différents lots sont accessibles en fonction du produit acheté :<br />une activité en lien avec le produit ou un livre photo à gagner.</p>
        </div>
        <div class="mobile points-mobile">
           <img src="../assets/images/point2.jpg">
             <p >avant le 31/03/2021 inclus.</p>
           <img src="../assets/images/point3.jpg">
             <p >La photo du ticket de caisse/facture du produit, en entourant le libellé, le prix et la date d’achat du produit, ainsi que la photo du code-barres du produit. Si ces informations ne sont pas clairement visibles, la demande de participation sera nulle et ne pourra être traitée.</p>
        
           <img src="../assets/images/point4.jpg">  
               <p>Les différents lots sont accessibles en fonction du produit acheté :<br />une activité en lien avec le produit ou un livre photo à gagner.</p>
                       
        </div>
        <div class="mobile" style="position:relative;"><img width="100%"  src="../assets/images/bas-comment.jpg">
            <router-link to="/form" ><img src="../assets/images/bount-jouez.jpg" class="comment-jouer"></router-link>  
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: 'howto' 
}
</script>
